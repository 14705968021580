import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

function PostFooter({ category }) {
  const slug = `/blog/category/${category
    .toLowerCase()
    .split(', ')
    .join('-')}`;

  return (
    <p style={{ marginTop: '2rem', color: '#777' }}>
      Posted to <Link to={slug}>{category}</Link>
    </p>
  );
}

PostFooter.propTypes = {
  category: PropTypes.string.isRequired,
};

export default PostFooter;
